.ReferralLinks-content {
  .select {
    border-radius: 8px;
    background-color: $white;
    max-width: 450px;
    padding: 40px 20px;
    margin-top: 20px;

    p {
      font-size: 15px;
      color: $blue;
      text-transform: uppercase;
      line-height: 1.067;
      display: inline-block;
      @include rtl-sass-prop(margin-right, margin-left, 20px);
    }

    .long-text {
      @include rtl-sass-prop(margin-right, margin-left, 8px);
    }

    .el-select /deep/ {
      display: inline-block;
      width: 200px;

      .el-input__inner {
        border-radius: 4px;
        background: $blue url(~@/assets/images/account_select.png) no-repeat 90% center;
        font-size: 15px;
        line-height: 2.398;
        text-align: center;
        @include rtl-sass-prop(padding-left, padding-right, 0);
        height: 36px;
        color: $white;
      }
    }
  }

  .info-box .copy-line {
    display: grid;
    grid-template-columns: calc((100% - 20px * 2) / 3) calc((100% - 20px * 2) / 3) calc((100% - 20px * 2) / 3);
    column-gap: 20px;
    margin-top: 20px;

    > div {
      min-height: 185px;
      border-radius: 8px;
      background-color: $white;
      padding: 20px;

      p {
        font-size: 15px;
        color: $blue;
        line-height: 1.067;
      }

      .copy {
        display: flex;
        height: calc(100% - 20px);

        .img {
          width: 74px;
          height: 74px;
          @include rtl-sass-prop(margin-right, margin-left, 20px);
          display: inline-block;
          align-self: flex-end;
          opacity: 0.329;
          cursor: pointer;

          img:active {
            animation: shake 0.5s;
          }
        }

        .url {
          width: calc(100% - 94px);
          display: inline-block;
          align-self: flex-end;

          .fl {
            border-style: solid;
            border-width: 1px;
            border-color: rgba(39, 5, 109, 0.1);
            background-color: $light-gray;
            width: calc(100% - 73px);
            height: 36px;
            @include rtl-sass-value(border-radius, 8px 0 0 8px, 0 8px 8px 0);

            p {
              text-overflow: ellipsis;
              white-space: nowrap;
              overflow: hidden;
              line-height: 36px;
              @include rtl-sass-prop(margin-left, margin-right, 15px);

              font-size: 12px;
              color: $dark-gray;
              text-align: center;
            }
          }

          .fr {
            background-color: $blue;
            width: 73px;
            height: 36px;
            text-align: center;
            @include rtl-sass-value(border-radius, 0 8px 8px 0, 8px 0 0 8px);
            cursor: pointer;

            span {
              font-size: 12px;
              color: $white;
              line-height: 36px;
            }
          }
        }
      }

      &.active {
        .copy {
          .img {
            opacity: 1;
          }

          .url {
            .fl p {
              color: $blue;
            }
            .fr {
              background-color: $red;
            }
          }
        }
      }
    }
  }
}
.qrcode-share {
  .top {
    margin: 0 auto 20px;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-content: center;
    padding: 0 20px;

    img {
      border-radius: 6px;
      background-color: $white;
      opacity: 0.6;
      width: 100%;
    }
  }

  .a2a-kit {
    padding: 0 20px;
    a {
      margin-bottom: 10px;
    }
  }

  .bottom {
    margin-top: 10px;
    img {
      @include rtl-sass-value(float, right, left);
    }
  }
}

@keyframes shake {
  0% {
    transform: translate(1px, 1px) rotate(0deg);
  }
  10% {
    transform: translate(-1px, -2px) rotate(-1deg);
  }
  20% {
    transform: translate(-3px, 0px) rotate(1deg);
  }
  30% {
    transform: translate(3px, 2px) rotate(0deg);
  }
  40% {
    transform: translate(1px, -1px) rotate(1deg);
  }
  50% {
    transform: translate(-1px, 2px) rotate(-1deg);
  }
  60% {
    transform: translate(-3px, 1px) rotate(0deg);
  }
  70% {
    transform: translate(3px, 1px) rotate(-1deg);
  }
  80% {
    transform: translate(-1px, -1px) rotate(1deg);
  }
  90% {
    transform: translate(1px, 2px) rotate(0deg);
  }
  100% {
    transform: translate(1px, -2px) rotate(-1deg);
  }
}

@media (max-width: 1200px) {
  .ReferralLinks-content {
    .select {
      max-width: 100%;
    }

    .info-box .copy-line {
      grid-template-columns: calc((100% - 20px) / 2) calc((100% - 20px) / 2);
      row-gap: 20px;
    }
  }
}

@media (max-width: 768px) {
  .ReferralLinks-content .info-box .copy-line > div {
    padding: 10px;
    .copy {
      display: grid;
      grid-template-columns: repeat(auto-fit, minmax(100%, 1fr));
      .img {
        margin: auto;
      }

      .url {
        width: 100%;
      }
    }
  }
}

@media (max-width: 767px) {
  .ReferralLinks-content {
    .select {
      padding: 20px;

      p {
        margin-bottom: 10px;
      }

      .el-select /deep/ {
        width: 100%;
      }
    }
    .info-box .copy-line {
      grid-template-columns: repeat(auto-fit, minmax(100%, 1fr));
    }
  }
}
